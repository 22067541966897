<style scoped lang="less">
.c_online_test_detail {
  .stu-module-header {
    background: #fff;
    padding: 20px;
    box-shadow: @shadow;
    .stu-module-title {
      div:first-child {
        font-weight: bold;
      }
      .font14 {
        margin-top: 15px;
        font-size: 14px;
      }
    }
  }
  .info {
    background: #fff;
    box-shadow: @shadow;
    margin-top: 20px;
    .stu-module-header {
      box-shadow: none;
    }
    .form {
      padding: 0 20px 10px;
      .form_item {
        margin-bottom: 10px;
        p {
          display: inline-block;
        }
        .label {
          color: #999;
          font-weight: bold;
        }
      }
    }
  }
  .c_online_test_detail_table {
    box-shadow: @shadow;
    margin-top: 20px;
    padding: 20px;
    background: #fff;
    .table_title {
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
}
</style>
<template>
  <div class="c_online_test_detail">
    <div class="stu-module-header">
      <div class="stu-module-title">
        <div>考试须知</div>
        <div class="font14">{{ items && items.answerDesc }}</div>
      </div>
    </div>
    <div class="info">
      <div class="stu-module-header">
        <div class="stu-module-title">
          <div>{{ items && items.examName }}</div>
        </div>
        <el-button type="primary" @click="back">返回</el-button>
      </div>
      <el-row class="form" v-if="items">
        <el-col class="form_item" :md="8" :sm="12">
          <p class="label">开始时间：</p>
          <p>{{ items.answerStartTime }}</p>
        </el-col>
        <el-col class="form_item" :md="8" :sm="12">
          <p class="label">考试时长：</p>
          <p>{{ items.examDuration }}</p>
        </el-col>
        <el-col class="form_item" :md="8" :sm="12">
          <p class="label">可答题次数：</p>
          <p>{{ items.answerNum }}</p>
        </el-col>
        <el-col class="form_item" :md="8" :sm="12">
          <p class="label">截止时间：</p>
          <p>{{ items.answerEndTime }}</p>
        </el-col>
        <el-col class="form_item" :md="8" :sm="12">
          <p class="label">试卷总分：</p>
          <p>{{ items.totalScore }}</p>
        </el-col>
        <el-col class="form_item" :md="8" :sm="12">
          <p class="label">已答题次数：</p>
          <p>{{ items.alreadyAnswerNum }}</p>
        </el-col>
        <el-col :span="24" style="margin: 10px 0">
          <template v-if="comparedate(items.answerEndTime)">
            <el-button
              :disabled="items.alreadyAnswerNum === items.answerNum"
              @click="startExam"
              type="primary"
              >开始作答</el-button
            >
          </template>
          <span v-else style="color: #ff0000">不在考试设置时间内</span>
        </el-col>
      </el-row>
    </div>
    <div class="c_online_test_detail_table">
      <div class="table_title">答题记录</div>
      <el-table stripe :data="tableData" :height="height">
        <el-table-column
          label="序号"
          type="index"
          width="65"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in col"
          :key="index"
          :label="item.label"
          :prop="item.prop"
          :min-width="item.width"
          :formatter="item.formatter"
          align="center"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column label="操作" width="80" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="onRecord(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { QueryStudentExamDetails } from "@/libs/api/online";
import { dateTimeFormat } from "@/tools/date";
export default {
  name: "c_online_test_detail",
  data() {
    return {
      height: window.innerHeight - 600,
      items: null,
      tableData: [],
      col: [
        {
          label: "提交时间",
          prop: "commitTime",
          width: 120,
          formatter: (row) => {
            if (row.commitTime) {
              return dateTimeFormat(row.commitTime);
            }
            return "";
          },
        },
        { label: "用时（分）", prop: "useDuration" },
        {
          label: "状态",
          prop: "status",
          formatter: (row) => {
            return row.status === 0
              ? "未批阅"
              : row.status === 1
              ? "已批阅"
              : "";
          },
        },
        { label: "分数", prop: "score" },
      ],
    };
  },
  computed: {
    comparedate() {
      return (date) => {
        // 截止时间
        let endDate = new Date(date).getTime();
        // 当前时间
        let nowDate = new Date().getTime();
        if (endDate == nowDate) {
          return true;
        } else if (endDate > nowDate) {
          // 在考试时间范围内
          return true;
        } else {
          return false;
        }
      };
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
  mounted() {
    const id = this.$route.query.id;
    if (id) this.examDetails(id);

    window.addEventListener("message", () => {
      this.examDetails(id);
    });
  },
  beforeDestroy() {
    window.removeEventListener("message", () => {});
  },
  methods: {
    examDetails(id) {
      QueryStudentExamDetails(id).then((res) => {
        this.items = res.data;
        this.tableData = res.data.examRecords;
        this.tableData.forEach((item) => {
          // if(){
          //
          // }
        });
      });
    },
    onRecord(row) {
      if (row) {
        const { id, studentId } = row;
        let routeData = this.$router.resolve({
          path: "/answer/student/record",
          query: { id, studentId },
        });
        window.open(routeData.href, "_blank");
      }
    },
    startExam() {
      //
      const id = this.$route.query.id;
      if (this.items) {
        sessionStorage.setItem(
          "writeTime",
          JSON.stringify(this.items.examDuration)
        );
      }
      let routeData = this.$router.resolve({
        path: "/answer/student/write",
        query: { id },
      });
      window.open(routeData.href, "_blank");
    },
    back() {
      this.$router.push("/student/c/online/test");
    },
  },
};
</script>